<template>
    <div class="home">
        <Breadcrumb :crumbData="crumbData"></Breadcrumb>
        <Row justify="start">
            <Form ref="queryParams" :model="queryParams" inline>
                <i-col span="4">
                    <FormItem label="关键字" prop="keyWord">
                        <i-input
                            v-model="queryParams.maybeStr"
                            clearable
                            placeholder="患者姓名、手机号码"
                        ></i-input>
                    </FormItem>
                </i-col>
                <i-col span="8">
                    <FormItem label="会员日期">
                        <Row>
                            <i-col span="11">
                                <FormItem prop="date">
                                    <DatePicker
                                        type="date"
                                        placeholder="开始时间"
                                        v-model="queryParams.startDate"
                                        @on-change="changStartTime"
                                    ></DatePicker>
                                </FormItem>
                            </i-col>
                            <i-col span="2" style="text-align: center">-</i-col>
                            <i-col span="11">
                                <FormItem prop="time">
                                    <DatePicker
                                        type="date"
                                        placeholder="结束时间"
                                        v-model="queryParams.endDate"
                                        @on-change="changEndTime"
                                    ></DatePicker>
                                </FormItem>
                            </i-col>
                        </Row>
                    </FormItem>
                </i-col>
                <i-col span="4">
                    <FormItem>
                        <Button
                            type="primary"
                            icon="ios-search"
                            @click="refreshTable"
                        >查询</Button
                        >
                        <Button
                            type="primary"
                            icon="md-refresh"
                            ghost
                            style="margin-left: 20px;background: rgba(232, 240, 255, 1)"
                            @click="handleReset('queryParams')"
                        >重置</Button
                        >
                    </FormItem>
                </i-col>
            </Form>
        </Row>
        <Row :gutter="16">
            <i-col span="24">
                <Table
                    border
                    stripe
                    :columns="tableColumns"
                    :data="tableData"
                    :loading="tableLoading"
                ></Table>
            </i-col>
        </Row>
        <br />
        <Row :gutter="16" type="flex" justify="end">
            <i-col span="15">
                <Page
                    :total="queryParams.totalRecord"
                    :current="queryParams.pageNumber + 1"
                    :page-size="queryParams.pageSize"
                    @on-change="pIndexChange"
                    @on-page-size-change="pSizeChange"
                    show-sizer
                    show-total
                    show-elevator
                />
            </i-col>
        </Row>
    </div>
</template>
<script>
    import { getCookieUID, getCookieUNAME } from "@js/session.sg";
    import Breadcrumb from "@/components/Breadcrumb";
    export default {
        components: {
            Breadcrumb
        },
        data() {
            return {
                loading1: false, // 下拉框是否为搜索状态
                hospitalList: [], // 机构数据列表
                crumbData: [

                    {
                        path: "",
                        name: "订单列表"
                    }
                ],
                queryParams: {
                    pageNumber: 0,
                    pageSize: 10,
                    totalRecord: 0,
                    maybeStr: "",
                    startDate: "",
                    endDate: "",
                },
                tableColumns: [
                    {
                        title: "序号",
                        width: 70,
                        align: "center",
                        render: (h, params) => {
                            return h(
                                "span",
                                {},
                                params.index +
                                1 +
                                this.queryParams.pageNumber *
                                this.queryParams.pageSize
                            );
                        }
                    },
                    {
                        title: "患者姓名",
                        align: "center",
                        key: "nickName"
                    },
                    {
                        title: "手机号",
                        align: "center",
                        key: "phoneNumber"
                    },
                    {
                        title: "金额（元）",
                        align: "center",
                        key: "payMoney"
                    },
                    {
                        title: "支付方式",
                        key: "payWay",
                        align: "center"
                    },
                    {
                        title: "会员开始日期",
                        align: "center",
                        width: 180,
                        key: "startDate"
                    },
                    {
                        title: "会员结束日期",
                        align: "center",
                        width: 180,
                        key: "endDate"
                    },
                ],
                tableData: [],
                tableLoading: false,
            };
        },
        mounted() {
            this.initTable();
        },
        methods: {
            // 开始时间选中
            changStartTime(value) {
                this.queryParams.startDate = value;
            },
            // 结束时间选择
            changEndTime(value) {
                this.queryParams.endDate = value;
            },
            initTable: function() {
                this.tableLoading = true;
                this.$http
                    .gets(
                        "/backstage/user/upgrade/order/getUserUpgradeOrderPage", this.queryParams

                    )
                    .then(res => {
                        console.log(res);
                        if (res.code === 1) {
                            // todo
                            this.tableData = res.data.list;
                            this.queryParams.totalRecord = res.data.total;
                            console.log(res);
                            this.tableLoading = false;
                        } else {
                            this.tableLoading = false;
                        }
                    })
                    .catch(err => {
                        this.tableLoading = false;
                        console.log(err);
                    });
            },
            // 重置
            handleReset (name) {
                this.$refs[name].resetFields();
                this.queryParams.maybeStr = "";
                this.queryParams.startDate = "";
                this.queryParams.endDate = "";
            },
            refreshTable: function() {
                this.queryParams.pageNumber = 0;
                this.initTable();
            },
            pIndexChange: function(page) {
                this.queryParams.pageNumber = page - 1;
                this.initTable();
            },
            pSizeChange: function(size) {
                this.queryParams.pageSize = size;
                this.initTable();
            },
        }
    };
</script>
<style lang="scss" scoped>
    @import "@css/tableCenter";
    .home {
        height: 100%;
        width: 100%;
    }
    ::v-deep .ivu-select-selection{
        width: 100px;
    }
    /*修改 表单间距*/
    ::v-deep .ivu-form-item {
        display: flex;
        align-items: center;
        .ivu-form-item-label {
            text-align: left;
            padding: 0;
            margin-right: 10px;
            white-space: nowrap;
        }
    }

    ::v-deep .patientDate {
        .ivu-modal {
            width: 910px !important;
        }
        .ivu-modal-body {
            height: 500px;
            overflow: auto;
            padding: 20px 40px;
            .detailone {
                &:last-child {
                    margin-top: 24px;
                }
                .title {
                    display: flex;
                    align-items: center;
                    img {
                        width: 30px;
                        height: 22px;
                        margin-right: 10px;
                    }
                    h1 {
                        font-size: 16px;
                        font-weight: 500;
                        color: #17233d;
                    }
                }
                .detail {
                    padding: 23px 35px;
                    background: #f6f7fb;
                    border: 1px solid #d7d9de;
                    border-radius: 2px;
                    margin-top: 12px;
                    .one {
                        display: flex;
                        align-items: center;
                        h1 {
                            font-size: 14px;
                            font-weight: 400;
                            color: #515a6e;
                            margin-right: 14px;
                        }
                        p {
                            font-size: 14px;
                            font-weight: 400;
                            color: #515a6e;
                            opacity: 0.7;
                        }
                    }
                    .history {
                        display: flex;
                        align-items: center;
                        margin-bottom: 10px;
                        h1 {
                            font-size: 14px;
                            font-weight: 400;
                            color: #515a6e;
                            margin-right: 14px;
                        }
                        p {
                            font-size: 14px;
                            font-weight: 400;
                            color: #515a6e;
                            opacity: 0.7;
                        }
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
</style>
